import React, { useState } from "react";

const ContactForm = () => {

  return (
    <form>
      <div className="field half first">
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" required />
      </div>
      <div className="field half">
        <label htmlFor="email">Email</label>
        <input type="text" name="email" id="email" required pattern="[^@\s]+@[^@\s]+\.[^@\s]+" title="Invalid email address!" />
      </div>
      <div className="field">
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" rows="4" required></textarea>
      </div>
      <ul className="actions">
        <li>
          <input type="submit" value="Send Email" className="special" />
        </li>
        <li>
          <input type="reset" value="Reset" />
        </li>
      </ul>
    </form>
  );
};

export default ContactForm;